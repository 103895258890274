var userAgent = navigator.userAgent.toLowerCase()
if(userAgent.indexOf('electron/')>-1) {
    console.log('桌面应用')
    var electron = window.require('electron')
}else {
    console.log('浏览器')
}
module.exports = {
    install: function (Vue) {
        Object.defineProperties(Vue.prototype, {
            $electron: {
                get () {
                    return electron
                },
            },
        })
    },
}
