import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import {Message} from "element-ui";
import qs from 'qs'
import MyLoading from './plugs/loading'
import Print from 'vue-print-nb'
import * as echarts from 'echarts'
import ElementUI from 'element-ui';
// import VueDragResize from 'vue-drag-resize'
import './assets/css/element-variables.scss';
import vueElectron from './plugs/vueElectron'
import touch from 'vue-hand-mobile'


// 请求处理
// axios.defaults.baseURL = 'http://wsye.shoupinkangda.com/'
axios.defaults.baseURL = 'http://wsye.dxtk-tech.com/'
// axios.defaults.baseURL = 'http://192.168.8.189:88/'
axios.interceptors.request.use(config => {
    let token = ((store.state || {}).user || {}).token;
    let hukou = ((store.state || {}).user || {}).hukou;
    if (token && hukou) {
        config.headers.token = token+','+hukou;
    }
    return config;
}, error => {
    return error;
});
axios.interceptors.response.use(
    response => {
        switch (parseInt(response.data.code)) {
            case 1:
                // 成功
                if (response.data.info && response.data.info !== 'success') {
                    // response.config.url.indexOf('term') !== 0 && Message.success(response.data.info)
                    response.config.url.indexOf('term') !== 0
                }
                return response;

            case 3020001:
                Message.error('请登录后操作');

                // 需要登录
                store.commit('setToken', '');
                // if (sessionStorage.getItem('usertype') !== 'center') {
                //     store.commit('setTerminalUserInfo', {data: '', status: false});
                //     router.push('/TerminalLogin')
                // } else {
                //     store.commit('setUserInfo', {data: '', status: false});
                //     router.push('/Login')
                // }
                store.commit('setUserInfo', {data: '', status: false});
                router.push('/Login')
                return response;

            default:
                // 失败
                Message.error(response.data.info);

                return response
        }
    },

    error => {
        Message.error('网络异常，请稍后再试')
        return error;
    }
);

// 路由处理
router.beforeEach((to, from, next) => {
    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.name == 'Login') {
        next();
    } else {
        if(window.location.hash.match('TerminalAnswerScreen') || window.location.hash.match('ReportPage')) {
            next()
        } else {
            if (store.state.user.loginStatus){
                if(!to.name) {
                    next('/Login')
                } else {
                    next()
                }
            }else {
                next('/Login')
            }
        }
    }
    // if (to.name === 'Login' || to.name === 'TerminalLogin') {
    //     if(to.name==='Login'){
    //         sessionStorage.setItem('usertype','center');
    //     }else if(to.name==='TerminalLogin'){
    //         sessionStorage.setItem('usertype','term');
    //     }
    //     next();
    // } else {
    //     console.log('window.isTerminal',window.isTerminal)
    //     if (store.state.user.loginStatus){
    //         next()
    //     }else {
    //         if (sessionStorage.getItem('usertype') === 'center' ) {
    //             next('/Login')
    //         }else {
    //             if (store.state.user.isClient){
    //                 next()
    //             }else {
    //                 next('/TerminalLogin');
    //             }
    //         }
    //     }
    //
    //
    //     /*if (to.meta.type === 'terminal' || window.isTerminal) {
    //         if (store.state.user.terminal.loginStatus) {
    //             next()
    //         } else {
    //             next('/TerminalLogin');
    //         }
    //     } else {
    //         if (store.state.user.loginStatus) {
    //             next()
    //         } else {
    //             next('/Login')
    //         }
    //     }*/
    // }
});

Vue.use(MyLoading)
Vue.use(Print);
Vue.use(touch);
// Vue.use(Hammer);
Vue.prototype.$qs = qs;
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(vueElectron)
// Vue.component('vue-drag-resize', VueDragResize)
Vue.prototype.$bus = new Vue()
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
